@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .form-field {
    @apply bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
  }

  .form-label{
    @apply block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300
  }

  .select-field{
    @apply appearance-none block w-full
    px-3
    py-2
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding bg-no-repeat
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
  }


  .save-btn{
    @apply text-white  bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800
  }

  .phone-mt{
    @apply lg:mt-0 md:mt-0 mt-5
  }

  .bg-section{
    @apply bg-[#323232]
  }
  .bg-top-bar{
    @apply bg-[#f58634]
  }
  .text-orange{
    @apply text-[#f58634]
  }

  
  .bg-orange-hover{
    @apply bg-[#f08132]
  }
  .bg-orange-dark{
    @apply bg-[#ad5b1d]
  }
  .text-primary-hover{
    @apply hover:text-[#f58634]
  }

  .card-bg{
    @apply bg-[#fff] text-black border border-gray-200
  }

  .section-my{
    @apply my-16
  }

  .section-py{
    @apply py-16
  }
  
  .bg-secondary{
    @apply bg-[#302f2f] text-white
  }




  .p-first-page-link{
    @apply py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white
  }
  .p-last-page-link{
    @apply py-2 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white
  }
  .page-item{
    @apply  py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white cursor-pointer
  }

  .p-active{
    @apply  py-2 px-3 text-white bg-[#5e35b1] border border-gray-300 hover:bg-[#6941b8] dark:border-gray-700 dark:bg-gray-700 dark:text-white
  }


}